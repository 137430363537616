<template>
  <q-card>
    <q-card-section>
      <div class="text-h6">Handmatige Invoer</div>
    </q-card-section>

    <q-card-section class="q-pt-none">
      <q-list dense>
        <q-item>
          <q-item-section>
            <div>
              Frequentie:
              <span class="text-weight-bold">
                {{ frequency }}
              </span>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <div>
              Minimale waarde:
              <span v-if="min_value" class="text-weight-bold">
                {{
                  new Intl.NumberFormat("nl-NL", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(min_value)
                }},-
              </span>
              <span v-else class="text-weight-bold"> NVT </span>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <div>
              Maximale waarde:
              <span v-if="max_value" class="text-weight-bold">
                {{
                  new Intl.NumberFormat("nl-NL", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(max_value)
                }},-
              </span>
              <span v-else class="text-weight-bold"> NVT </span>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
      <q-form ref="amountRef">
        <q-input
          outlined
          dense
          placeholder="Hoeveelheid"
          v-model="amount"
          :rules="manualAmountRules"
        ></q-input>
      </q-form>
    </q-card-section>

    <q-card-actions align="right">
      <q-btn flat label="Terug" color="primary" v-close-popup />
      <q-btn label="Opslaan" color="primary" @click="emitAmount()" />
    </q-card-actions>
  </q-card>
</template>

<script setup>
import { ref } from "@vue/reactivity";

//* Emits
// eslint-disable-next-line no-undef
const emit = defineEmits(["emitAmount"]);

//* Props
// eslint-disable-next-line no-undef
const props = defineProps({
  min_value: Number,
  max_value: Number,
  max_year_value: Number,
  frequency: String,
  calculation_type: String,
});

//* VueUse

//* Refs
const amount = ref(null);
const amountRef = ref(null);
const manualAmountRules = ref([
  (val) => !!val || "Vul een waarde in",
  (val) =>
    (!isNaN(val) && !isNaN(parseFloat(val))) || "Alleen nummers toegestaan",
]);
//* Computed

//*  Methods
function emitAmount() {
  amountRef.value.validate().then((success) => {
    if (!success) return;

    emit("emitAmount", amount.value);
  });
}

//* Conditions
if (props.min_value) {
  manualAmountRules.value.push(
    (val) =>
      parseFloat(val) >= props.min_value ||
      `Waarde moet hoger zijn dan ${props.min_value}`
  );
}
if (props.max_value) {
  manualAmountRules.value.push(
    (val) =>
      parseFloat(val) <= props.max_value ||
      `Waarde moet lager zijn dan ${props.max_value}`
  );
}
</script>
<style lang="sass"></style>
